.radio label {
  display: flex;
  align-items: center;
  align-content: center;
  padding: .5em 0;
  margin: 0;
}

.radio label:hover,
.radio input:hover {
  cursor: pointer;
}

.radio p {
  margin: 0 0;
  font-size: 13px;
  font-weight: 300;
}

.radio input {
  margin: 0 10px;
}

.radio label:before{
  width: 14px;
  height: 14px;
  border: 1px solid rgb(151, 151, 151);
}