.forgotpassword {
  margin-top: 30px;
}
.forgotpassword a,
.noaccount a{
  font-size: 14px;
  font-weight: 300;
}
.noaccount {
  margin-top: 65px;
}
.noaccount p {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
}
.input {
  margin: 25px 0;
}
input[type='submit'] {
  font-size: 13px;
  font-weight: 500;
}
.sign-up-header > img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.sign-up-header > h2 {
  font-size: 32px;
  margin: 35px 0;
  font-weight: 500;
  text-align: center;
  color: var(--secondary-title-color);
}
.sign-up-header > form {
  display: block;
  width: 300px;
  margin: 0 auto;
  max-width: 100%;
}