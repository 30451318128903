.station-photo {
  position: relative;
}

.station-photo .input {
  margin: 50px 0;
}
.station-photo h3 {
  font-size: 14px;
  font-weight: 500;
}

.station-photo p {
  font-size: 12px;
  font-weight: 300;
}

.loader-container {
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 80px;
  justify-content: center;
  align-items: center;
  z-index: 1;
}