:root {
  --main-bg-color: #FFF;
  --main-txt-color: #333231;
  --main-weight: 400;
  --main-border-color: #E8E8E8;
  --cp-brand-color: #FF7A14;
  --primary-button-color: #eb7f1c;
  --primary-button-color-hover: #FDC264;
  --primary-button-color-disabled: var(--primary-button-color-hover);
  --secondary-title-weight: 600;
  --secondary-title-color: #7b9cb0;
  --secondary-txt-color: #9b9b9b;
  --secondary-button-color: #C7C5C0;
  --secondary-button-color-hover: #E5E3DD;
  --secondary-button-color-disabled: var(--secondary-button-color-hover);
  --bold-weight: 700;
  --link-color: #4a90e2;
  --error-color: red;
  --dropdown-color-hover: #F2F1ED;
  --input-placeholder-color: #9b9b9b;
  --input-warning-color: #eb7f1c;
  --text-color: #4a4a4a;
}

/* ------------------------------------- */
/* GLOBAL */

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  color: var(--main-txt-color);
}

.App .main {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  line-height: normal;
  font-size: 16px;
  padding: 0 35px;
}

/* ------------------------------------- */
/* Fonts */

h1,
h2,
h3,
h4,
h5,
h6,
button,
a,
p {
  font-weight: var(--main-weight);
}

h3 {
  font-weight: var(--secondary-title-weight);
  margin: 10px 0;
}

/* ------------------------------------- */
/* Link */
a {
  color: var(--link-color);
  text-decoration: none;
}

.center {
  text-align: center;
}

/* ------------------------------------- */
/* Button */
button,
input[type='submit'] {
  width: 100%;
  display: block;
  position: relative;

  white-space: nowrap;
  padding: .5em 1em;

  background-color: var(--primary-button-color);
  border: 1px solid var(--primary-button-color);
  /* border-radius: .3125em; */
  cursor: pointer;

  text-align: center;

  font-size: 1em;
  font-weight: var(--main-weight);
  color: var(--main-bg-color);

}

button:hover,
input[type='submit']:hover {
  /* opacity: .5; */
  background-color: var(--primary-button-color-hover);
  border: 1px solid var(--primary-button-color-hover);
}


input[type='submit']:disabled,
button:disabled {
  background-color: var(--primary-button-color-disabled);
  border: 1px solid var(--primary-button-color-disabled);
  cursor: default;
}



button,
.input {
  margin: 2em 0;
}

/* ------------------------------------- */
/* Spacing */
/* EE_TODO: Change to standardize spacing ==> flex? or Grid? */

#checkbox {
  margin: 5px 0;
}

.main>h2 {
  margin: 35px 0;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: var(--secondary-title-color);
}

.main>p {
  padding-bottom: 20px;
}