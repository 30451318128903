:root {
  --main-bg-color: #FFF;
  --main-txt-color: #333231;
  --main-weight: 400;
  --main-border-color: #E8E8E8;
  --cp-brand-color: #FF7A14;
  --primary-button-color: #eb7f1c;
  --primary-button-color-hover: #FDC264;
  --primary-button-color-disabled: var(--primary-button-color-hover);
  --secondary-title-weight: 600;
  --secondary-title-color: #7b9cb0;
  --secondary-txt-color: #9b9b9b;
  --secondary-button-color: #C7C5C0;
  --secondary-button-color-hover: #E5E3DD;
  --secondary-button-color-disabled: var(--secondary-button-color-hover);
  --bold-weight: 700;
  --link-color: #4a90e2;
  --error-color: red;
  --dropdown-color-hover: #F2F1ED;
  --input-placeholder-color: #9b9b9b;
  --input-warning-color: #eb7f1c;
  --text-color: #4a4a4a;
}

/* ------------------------------------- */
/* GLOBAL */

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Gotham Narrow SSm A", "Gotham Narrow SSm B", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;
  background-color: var(--main-bg-color);
  color: #333231;
  color: var(--main-txt-color);
}

.App .main {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  line-height: normal;
  font-size: 16px;
  padding: 0 35px;
}

/* ------------------------------------- */
/* Fonts */

h1,
h2,
h3,
h4,
h5,
h6,
button,
a,
p {
  font-weight: 400;
  font-weight: var(--main-weight);
}

h3 {
  font-weight: 600;
  font-weight: var(--secondary-title-weight);
  margin: 10px 0;
}

/* ------------------------------------- */
/* Link */
a {
  color: #4a90e2;
  color: var(--link-color);
  text-decoration: none;
}

.center {
  text-align: center;
}

/* ------------------------------------- */
/* Button */
button,
input[type='submit'] {
  width: 100%;
  display: block;
  position: relative;

  white-space: nowrap;
  padding: .5em 1em;

  background-color: #eb7f1c;

  background-color: var(--primary-button-color);
  border: 1px solid #eb7f1c;
  border: 1px solid var(--primary-button-color);
  /* border-radius: .3125em; */
  cursor: pointer;

  text-align: center;

  font-size: 1em;
  font-weight: 400;
  font-weight: var(--main-weight);
  color: #FFF;
  color: var(--main-bg-color);

}

button:hover,
input[type='submit']:hover {
  /* opacity: .5; */
  background-color: #FDC264;
  background-color: var(--primary-button-color-hover);
  border: 1px solid #FDC264;
  border: 1px solid var(--primary-button-color-hover);
}


input[type='submit']:disabled,
button:disabled {
  background-color: #FDC264;
  background-color: var(--primary-button-color-disabled);
  border: 1px solid #FDC264;
  border: 1px solid var(--primary-button-color-disabled);
  cursor: default;
}



button,
.input {
  margin: 2em 0;
}

/* ------------------------------------- */
/* Spacing */
/* EE_TODO: Change to standardize spacing ==> flex? or Grid? */

#checkbox {
  margin: 5px 0;
}

.main>h2 {
  margin: 35px 0;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #7b9cb0;
  color: var(--secondary-title-color);
}

.main>p {
  padding-bottom: 20px;
}
.subheader {
  text-align: center;
  margin: 15px 0;
}

.subheader h2 {
  color: var(--secondary-title-color);
  font-weight: 500;
  font-size: 16px;
}

.subheader p {
  color: var(--secondary-txt-color);
  font-size: 14px;
  padding: 10px 0 0;
  font-weight: 300;
}
header {
  text-align: center;
  color: var(--secondary-title-color);
}

header img {
  margin-top: 38px;
}

header h2 {
  margin: 35px 0;
  font-weight: 500;
  font-size: 16px;
}

/* Logged In */
#loggedIn {
  text-align: left;
  margin: 0 auto;
  background-color: #f4f3ee;
  padding: 15px 20px;
}

#loggedIn > div {
  display: inline-flex;
  align-content: center;
  justify-content: flex-start;
}

#loggedIn p {
  color: #9b9b9b;
  font-size: 14px;
  padding-bottom: 5px;
  font-weight: 600;
  padding-top: 3px;
}
#loggedIn a {
  font-size: 14px;
  color: var(--secondary-title-color);
}
#loggedIn img {
  margin-right: 15px;
  margin-top: 0;
}

#loggedIn h2 {
  text-align: center;
  font-size: 1em;
  margin: 0;
}
.error {
  color: var(--error-color);
  margin: 2px 0;
  font-size: 14px;
}
.input {
  position: relative;
}


input {
  display: block;
  text-align: left;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  min-height: 1em;
  padding: .4em;
  font-size: 1em;
  font-weight: var(--main-weight);

  box-sizing: border-box;
  border: 0;
  border-bottom: 0.5px solid #9b9b9b;
  outline: 0;
}

.input .floating-label {
  position: absolute;
  pointer-events: none;
  color: var(--input-placeholder-color);
  top: 0;
  left: 0;
  transition: 0.1s ease all;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
}

.input .floating-label.onFocus {
  top: -1.5em;
  left: .4em;
  font-size: .7em;
}

.input .input-info {
  font-size: 12px;
  font-weight: 300;
  color: var(--input-placeholder-color);
}

.input .input-warning {
  font-weight: 300;
  color: var(--input-warning-color);
}
.forgotpassword {
  margin-top: 30px;
}
.forgotpassword a,
.noaccount a{
  font-size: 14px;
  font-weight: 300;
}
.noaccount {
  margin-top: 65px;
}
.noaccount p {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
}
.input {
  margin: 25px 0;
}
input[type='submit'] {
  font-size: 13px;
  font-weight: 500;
}
.sign-up-header > img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.sign-up-header > h2 {
  font-size: 32px;
  margin: 35px 0;
  font-weight: 500;
  text-align: center;
  color: var(--secondary-title-color);
}
.sign-up-header > form {
  display: block;
  width: 300px;
  margin: 0 auto;
  max-width: 100%;
}
nav {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

.fullNav {
  margin-right: 20px;
}

a.successbutton {
  display: block;
  position: relative;

  white-space: nowrap;
  padding: .5em 1em;

  /* border-radius: .3125em; */
  cursor: pointer;

  text-align: center;

  font-size: 1em;
  color: var(--main-txt-color);
  background-color: var(--secondary-button-color);
  border-color: var(--secondary-button-color);

  margin: 30px 0;
}

.backButton {
  background: #c7c7c7;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  font-weight: 600;
  color: #4a4a4a;
}

.backButton:hover,
a.successbutton:hover {
  background-color: var(--secondary-button-color-hover);
  border-color: var(--secondary-button-color-hover);
}

.backButton:disabled {
  background-color: var(--secondary-button-color-disabled);
  border-color: var(--secondary-button-color-disabled);
}
button[type='submit'] {
  font-size: 13px;
  font-weight: 500;
  margin: 5px 0;
}

.radio label {
  display: flex;
  align-items: center;
  align-content: center;
  padding: .5em 0;
  margin: 0;
}

.radio label:hover,
.radio input:hover {
  cursor: pointer;
}

.radio p {
  margin: 0 0;
  font-size: 13px;
  font-weight: 300;
}

.radio input {
  margin: 0 10px;
}

.radio label:before{
  width: 14px;
  height: 14px;
  border: 1px solid rgb(151, 151, 151);
}
button[type='submit'] {
  margin: auto;
}
select {
  width: 100%;
  text-align: center;
  -webkit-appearance: none;
          appearance: none;
  border: 1px solid var(--main-border-color);
  cursor: pointer;
  border-radius: .3125em;
  padding: .5em 0;
  background-color: var(--main-bg-color);

  /* Chrome support*/
  text-align-last: center;
}

select:hover {
  background-color: var(--dropdown-color-hover);
  cursor: pointer;
}

.input-select label {
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  color: var(--input-placeholder-color);
}

.input-select .holder-select {
  font-size: 16px;
  background: #f8f8f8;
  border: 1px solid #9b9b9b;
  border-radius: 5px;
  color: var(--input-placeholder-color);
  padding: 3px 10px;
  margin-top: 5px;
  position: relative;
}


.input-select .holder-select::before {
  content: " ";
  color: #4a4a4a;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  position: absolute;
  right: 15px;
  top: 10px;
}

.input-select .holder-select select {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-align-last: left;
  font-size: 13px;
  font-weight: 300;
  color: #4a4a4a;
}
.success-holder p {
  font-size: 14px;
  font-weight: 300;
  color: #4a4a4a;
  text-align: center;
  margin: 35px auto;
}

.success-holder a {
  border: 1px solid #f5bf8e;
  color: #f5bf8e;
  font-size: 13px;
  font-weight: 500;
  background: none;
  padding: 5px 50px;
}

.success-holder img {
  display: block;
  margin: 0 auto 35px auto;

}
#forgetpassword>form>input[type='submit'] {
  margin-top: 2em;
}

p#forgetpassword {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
}

#forgetpassword.forgotpassword {
  margin-top: 43px;
}

.App>h2 {
  margin-bottom: 22px;
}
#pin {
  font-size: 3em;
  text-align: center;
  margin: 40px 0;
}
#main-pin {
  -webkit-transform: translateY(-80%) translateX(-50%);
          transform: translateY(-80%) translateX(-50%);
  width: 31px;
  height: 35px;
  background-size: 31px 35px;
  background-repeat: no-repeat;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(/images/pin.png);
  z-index: 20;
}

img.surrounding {
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%)
}

img.central {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%)
}
#map {
  height: 320px;
  margin: 0 -35px;
  position: relative;
  z-index: 19;
}
#centeredlineX {
  width: 100%;
  height: 1px;
  background-color: var(--main-bg-color);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

#centeredlineY {
  width: 1px;
  height: 100%;
  background-color: var(--main-bg-color);
  position: absolute;
  z-index: 20;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
#searchbox>i {
  background-image: url("/images/searchicon.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 1em;
  opacity: .5;
}

#searchbox>input[type="text"] {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin: 0
}

#searchbox {
  margin: 35px 0;
  display: inline-flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 2.2em;
  box-sizing: border-box;
  border: 0;
  border-bottom: 0.5px solid var(--input-placeholder-color);
  outline: 0;
  position: relative;
}


#searchbox.focus {
  /* box-shadow: 0 0 12px #38c; */
}

.pac-container {
  /* important needed to overide inline css */
  margin-top: 10px;
  max-width: 900px !important;
  width: 85% !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

#searcbbox-label {
  position: absolute;
  pointer-events: none;
  color: var(--input-placeholder-color);
  top: 0.5em;
  left: 0;
  transition: 0.1s ease all;
  font-size: 14px;
  font-weight: 300;
}

#searcbbox-label.onFocus {
  top: -1.5em;
  font-size: .7em;
}

input[type='submit'].hidden {
  display: none;
}
.addressMap .gm-style>div>div {
  cursor: default
}
.station-details h3 {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
}

.garage-image {
  text-align: center;
  margin: 30px 0 0;
  padding-bottom: 5px;
}

.withIcon {
  display: inline-block;
}

.accessibleIcon {
  vertical-align: middle;
  margin-left: .5em;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  width: 150px;
  display: block;
  position: relative;
  padding: 10px 35px;
  color: #eb7f1c;
  cursor: pointer;
  text-align: center;
  border: 1px solid #f5bf8e;
  font-size: 13px;
  font-weight: 500;
  margin: 20px auto 30px;
}


.inputfile:focus+label,
.inputfile+label:hover {
  background-color: var(--secondary-button-color-hover);
}

/* .inputfile:focus+label {
  outline: 1px dotted #000;
} */

.imageFile {
  display: block;
  margin: 50px auto;
  width: 80%;
  max-width: 500px;
}


.defaultImageFile {
  display: block;
  margin: 50px auto;
  width: 150px;
}

/* .inputfile+label * {
  pointer-events: none;
} */
.station-photo {
  position: relative;
}

.station-photo .input {
  margin: 50px 0;
}
.station-photo h3 {
  font-size: 14px;
  font-weight: 500;
}

.station-photo p {
  font-size: 12px;
  font-weight: 300;
}

.loader-container {
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 80px;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.terminalblock-title-photo {
  margin-top: 33px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 700;
}

.terminalblock-paragraph-photo {
  margin-top: 9px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 300;
}

.terminal-block img {
  width: 252px;
  margin-top: 28px;
  margin-bottom: 28px;
}
#address-form {
  margin-top: 20px
}
.panel-label {
    margin-top: 32px;
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 700;
}

.input .floating-label {
    color: rgb(155, 155, 155);
    font-size: 14px;
    font-weight: 300;
}

input[name="panel_id"] {
    padding: 0em;
}

.select-amps-label {
    margin-top: 64px;
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 300;
}

.input-select {
    margin-top: 10px;
    margin-bottom: 0px;
}

select[name="amps"] option {
    color: rgb(51, 50, 49);
    font-size: 14px;
    font-weight: 300;
    line-height: 10px;
}

.phase-label {
    margin-top: 30px;
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 300;
}

.radio label {
    display: inline-flex;
}

.radio label input {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 8px;
}

.radio label p {
    color: rgb(74, 74, 74);
    font-size: 13px;
    font-weight: 300;
    margin-right: 30px;
}
.phaseconnection-label {
  margin-top: 30px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 700;
}

.sticker-reference-hint {
  margin-top: 10px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 300;
}

.phaseconnection-image {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 5px;
}

.panel-phases-question {
  margin-top: 15px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 300;
}

.input-select {
  margin-top: 10px;
  margin-bottom: 0px;
}

select[name="phase_connection"] option {
  color: rgb(51, 50, 49);
  font-size: 14px;
  font-weight: 300;
  line-height: 10px;
}

.selection-note {
  margin-top: 25px;
  color: rgb(155, 155, 155);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
