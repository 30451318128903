.phaseconnection-label {
  margin-top: 30px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 700;
}

.sticker-reference-hint {
  margin-top: 10px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 300;
}

.phaseconnection-image {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 5px;
}

.panel-phases-question {
  margin-top: 15px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 300;
}

.input-select {
  margin-top: 10px;
  margin-bottom: 0px;
}

select[name="phase_connection"] option {
  color: rgb(51, 50, 49);
  font-size: 14px;
  font-weight: 300;
  line-height: 10px;
}

.selection-note {
  margin-top: 25px;
  color: rgb(155, 155, 155);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}