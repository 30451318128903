.success-holder p {
  font-size: 14px;
  font-weight: 300;
  color: #4a4a4a;
  text-align: center;
  margin: 35px auto;
}

.success-holder a {
  border: 1px solid #f5bf8e;
  color: #f5bf8e;
  font-size: 13px;
  font-weight: 500;
  background: none;
  padding: 5px 50px;
}

.success-holder img {
  display: block;
  margin: 0 auto 35px auto;

}