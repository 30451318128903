header {
  text-align: center;
  color: var(--secondary-title-color);
}

header img {
  margin-top: 38px;
}

header h2 {
  margin: 35px 0;
  font-weight: 500;
  font-size: 16px;
}

/* Logged In */
#loggedIn {
  text-align: left;
  margin: 0 auto;
  background-color: #f4f3ee;
  padding: 15px 20px;
}

#loggedIn > div {
  display: inline-flex;
  align-content: center;
  justify-content: flex-start;
}

#loggedIn p {
  color: #9b9b9b;
  font-size: 14px;
  padding-bottom: 5px;
  font-weight: 600;
  padding-top: 3px;
}
#loggedIn a {
  font-size: 14px;
  color: var(--secondary-title-color);
}
#loggedIn img {
  margin-right: 15px;
  margin-top: 0;
}

#loggedIn h2 {
  text-align: center;
  font-size: 1em;
  margin: 0;
}