.panel-label {
    margin-top: 32px;
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 700;
}

.input .floating-label {
    color: rgb(155, 155, 155);
    font-size: 14px;
    font-weight: 300;
}

input[name="panel_id"] {
    padding: 0em;
}

.select-amps-label {
    margin-top: 64px;
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 300;
}

.input-select {
    margin-top: 10px;
    margin-bottom: 0px;
}

select[name="amps"] option {
    color: rgb(51, 50, 49);
    font-size: 14px;
    font-weight: 300;
    line-height: 10px;
}

.phase-label {
    margin-top: 30px;
    color: rgb(74, 74, 74);
    font-size: 14px;
    font-weight: 300;
}

.radio label {
    display: inline-flex;
}

.radio label input {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 8px;
}

.radio label p {
    color: rgb(74, 74, 74);
    font-size: 13px;
    font-weight: 300;
    margin-right: 30px;
}