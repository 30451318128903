#forgetpassword>form>input[type='submit'] {
  margin-top: 2em;
}

p#forgetpassword {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 300;
}

#forgetpassword.forgotpassword {
  margin-top: 43px;
}

.App>h2 {
  margin-bottom: 22px;
}