#main-pin {
  transform: translateY(-80%) translateX(-50%);
  width: 31px;
  height: 35px;
  background-size: 31px 35px;
  background-repeat: no-repeat;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(/images/pin.png);
  z-index: 20;
}

img.surrounding {
  transform: translate(-50%, -100%)
}

img.central {
  transform: translate(-50%, -50%)
}