#searchbox>i {
  background-image: url("/images/searchicon.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 1em;
  opacity: .5;
}

#searchbox>input[type="text"] {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin: 0
}

#searchbox {
  margin: 35px 0;
  display: inline-flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 2.2em;
  box-sizing: border-box;
  border: 0;
  border-bottom: 0.5px solid var(--input-placeholder-color);
  outline: 0;
  position: relative;
}


#searchbox.focus {
  /* box-shadow: 0 0 12px #38c; */
}

.pac-container {
  /* important needed to overide inline css */
  margin-top: 10px;
  max-width: 900px !important;
  width: 85% !important;
  left: 50% !important;
  transform: translateX(-50%);
}

#searcbbox-label {
  position: absolute;
  pointer-events: none;
  color: var(--input-placeholder-color);
  top: 0.5em;
  left: 0;
  transition: 0.1s ease all;
  font-size: 14px;
  font-weight: 300;
}

#searcbbox-label.onFocus {
  top: -1.5em;
  font-size: .7em;
}

input[type='submit'].hidden {
  display: none;
}