select {
  width: 100%;
  text-align: center;
  appearance: none;
  border: 1px solid var(--main-border-color);
  cursor: pointer;
  border-radius: .3125em;
  padding: .5em 0;
  background-color: var(--main-bg-color);

  /* Chrome support*/
  text-align-last: center;
}

select:hover {
  background-color: var(--dropdown-color-hover);
  cursor: pointer;
}

.input-select label {
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  color: var(--input-placeholder-color);
}

.input-select .holder-select {
  font-size: 16px;
  background: #f8f8f8;
  border: 1px solid #9b9b9b;
  border-radius: 5px;
  color: var(--input-placeholder-color);
  padding: 3px 10px;
  margin-top: 5px;
  position: relative;
}


.input-select .holder-select::before {
  content: " ";
  color: #4a4a4a;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  position: absolute;
  right: 15px;
  top: 10px;
}

.input-select .holder-select select {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-align-last: left;
  font-size: 13px;
  font-weight: 300;
  color: #4a4a4a;
}