#centeredlineX {
  width: 100%;
  height: 1px;
  background-color: var(--main-bg-color);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#centeredlineY {
  width: 1px;
  height: 100%;
  background-color: var(--main-bg-color);
  position: absolute;
  z-index: 20;
  left: 50%;
  transform: translateX(-50%);
}