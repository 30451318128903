.input {
  position: relative;
}


input {
  display: block;
  text-align: left;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  min-height: 1em;
  padding: .4em;
  font-size: 1em;
  font-weight: var(--main-weight);

  box-sizing: border-box;
  border: 0;
  border-bottom: 0.5px solid #9b9b9b;
  outline: 0;
}

.input .floating-label {
  position: absolute;
  pointer-events: none;
  color: var(--input-placeholder-color);
  top: 0;
  left: 0;
  transition: 0.1s ease all;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
}

.input .floating-label.onFocus {
  top: -1.5em;
  left: .4em;
  font-size: .7em;
}

.input .input-info {
  font-size: 12px;
  font-weight: 300;
  color: var(--input-placeholder-color);
}

.input .input-warning {
  font-weight: 300;
  color: var(--input-warning-color);
}