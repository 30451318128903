.terminalblock-title-photo {
  margin-top: 33px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 700;
}

.terminalblock-paragraph-photo {
  margin-top: 9px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  font-weight: 300;
}

.terminal-block img {
  width: 252px;
  margin-top: 28px;
  margin-bottom: 28px;
}