.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  width: 150px;
  display: block;
  position: relative;
  padding: 10px 35px;
  color: #eb7f1c;
  cursor: pointer;
  text-align: center;
  border: 1px solid #f5bf8e;
  font-size: 13px;
  font-weight: 500;
  margin: 20px auto 30px;
}


.inputfile:focus+label,
.inputfile+label:hover {
  background-color: var(--secondary-button-color-hover);
}

/* .inputfile:focus+label {
  outline: 1px dotted #000;
} */

.imageFile {
  display: block;
  margin: 50px auto;
  width: 80%;
  max-width: 500px;
}


.defaultImageFile {
  display: block;
  margin: 50px auto;
  width: 150px;
}

/* .inputfile+label * {
  pointer-events: none;
} */