.subheader {
  text-align: center;
  margin: 15px 0;
}

.subheader h2 {
  color: var(--secondary-title-color);
  font-weight: 500;
  font-size: 16px;
}

.subheader p {
  color: var(--secondary-txt-color);
  font-size: 14px;
  padding: 10px 0 0;
  font-weight: 300;
}