.station-details h3 {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
}

.garage-image {
  text-align: center;
  margin: 30px 0 0;
  padding-bottom: 5px;
}

.withIcon {
  display: inline-block;
}

.accessibleIcon {
  vertical-align: middle;
  margin-left: .5em;
}