nav {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

.fullNav {
  margin-right: 20px;
}

a.successbutton {
  display: block;
  position: relative;

  white-space: nowrap;
  padding: .5em 1em;

  /* border-radius: .3125em; */
  cursor: pointer;

  text-align: center;

  font-size: 1em;
  color: var(--main-txt-color);
  background-color: var(--secondary-button-color);
  border-color: var(--secondary-button-color);

  margin: 30px 0;
}

.backButton {
  background: #c7c7c7;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  font-weight: 600;
  color: #4a4a4a;
}

.backButton:hover,
a.successbutton:hover {
  background-color: var(--secondary-button-color-hover);
  border-color: var(--secondary-button-color-hover);
}

.backButton:disabled {
  background-color: var(--secondary-button-color-disabled);
  border-color: var(--secondary-button-color-disabled);
}